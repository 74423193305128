$(document).on('turbolinks:load', function () {
  $('input[type=radio]').on('click', function () {
    $('input[type=radio]:checked').not(this).prop('checked', false);
  });
  var searchField = $('#q_name_or_shop_name_or_shop_address_or_shop_phone_or_email_matches_all_in_fields');
  if (searchField.length) {
    searchField.on('input', function () {
      // 全角スペースを半角スペースに変換
      $(this).val($(this).val().replace(/　/g, ' '));
    });
  }
  // 「有料プランに一括登録」のチェックボックスの変更を監視
  $("#bulk_registration_checkbox").on('change', function () {
    // 他のユーザーのチェックボックスを「有料プランに一括登録」に合わせる
    $("input[name='user_ids[]']").prop('checked', $(this).prop('checked'));
  });

  // プラグインを登録
  gsap.registerPlugin(ScrollTrigger);

  // スクロールトリガーの設定
  gsap.utils.toArray(".box").forEach((target) => {
    gsap.timeline({
      scrollTrigger: {
        trigger: target,
        start: "top 60%",
        end: 'bottom 30%',
        scrub: true
      }
    })
      .from(target, {
        opacity: 0,
      });
  });
});